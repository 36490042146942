import "./DealGridItemView.css";

function DealGridItemView({ item, onClick }) {
  return (
    <div className="deal-grid-item" onClick={() => onClick(item)}>
      <img src={item.brand_image_url} alt={item.brand_name}></img>
    </div>
  );
}

export default DealGridItemView;
