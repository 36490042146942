import { Route, Routes, useLocation } from "react-router-dom";
import Landing from "./Landing";
import PageNotFoundView from "./PageNotFoundView";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Landing />}
      />
      ;<Route path="*" element={<PageNotFoundView />}></Route>
    </Routes>
  );
}

export default App;
