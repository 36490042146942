import "./DealsGridView.css";
import DealGridItemView from "./DealGridItemView";
import LoadingDealGridView from "./LoadingDealGridView";
import { useState, useEffect, useRef, useCallback } from "react";

function DealsGridView({ onClickItem }) {

  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false); // Pagination flag
  const listContainerRef = useRef(null);

  useEffect(() => {
    const fetchDeals = async () => {
      setIsLoading(true);
      const apiUrl =
        "https://us-central1-red-flag-or-deal-breaker.cloudfunctions.net/rfdb/pp";
      try {
        const response = await fetch(apiUrl, { headers: { "Access-Control-Allow-Origin": "*" } });
        const data = await response.json();
        setDeals(data.deals);
        setNextCursor(data.cursor);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeals();
  }, []);

  const fetchMoreDeals = async () => {
    console.log("fetchMoreDeals");
    if (!nextCursor || isFetchingMore) return; // Prevent multiple fetches

    setIsFetchingMore(true); // Set flag to prevent re-fetching
    try {
      const apiUrl = `https://us-central1-red-flag-or-deal-breaker.cloudfunctions.net/rfdb/pp?cursor=${nextCursor}`;
      const response = await fetch(apiUrl, { headers: { "Access-Control-Allow-Origin": "*" } });
      const data = await response.json();

      setDeals((prevDeals) => [...prevDeals, ...data.deals]);
      setNextCursor(data.cursor);
    } catch (error) {
      console.error("Error fetching more deals:", error);
    } finally {
      setIsFetchingMore(false); // Reset flag after fetch completes
    }
  };

  // Throttle the scroll handler to avoid multiple triggers
  const handleScroll = useCallback(() => {
    console.log("handleScroll");
    if (
      listContainerRef.current.scrollTop + listContainerRef.current.clientHeight >=
      listContainerRef.current.scrollHeight - 10 // Small buffer to trigger early
    ) {
      fetchMoreDeals();
    }

    sessionStorage.setItem("scrollPosition", listContainerRef.current.scrollTop);
  }, [fetchMoreDeals]);

  return (
    <div className="deals-container">
      <h1>Previous Deals</h1>
      {isLoading ? (
        <LoadingDealGridView />
      ) : (
        deals.length > 0 && (
          <div className="deals-grid-container" id="player-deals" ref={listContainerRef} onScroll={handleScroll}>
            {deals.map((deal, index) => (
              <DealGridItemView
                key={index}
                item={deal}
                onClick={() => onClickItem(deal)}
              />
            ))}
          </div>
        )
      )}
    </div>
  );
}

export default DealsGridView;
