import "./DealGridItemView.css";

function LoadingDealGridView() {
  return (
    <div class="deals-grid-container">
      {[...Array(20).keys()].map((deal, index) => (
        <div className="deal-grid-item" key={index}></div>
      ))}
    </div>
  );
}

export default LoadingDealGridView;
