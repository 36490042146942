import "./SocialsStack.css";

function SocialsStack() {
  return (
    <div class="socials-stack">
      <a href="mailto:brandon@playerspunch.com">
        <i
          class="fa fa-envelope-o"
          aria-hidden="true"
          style={{ color: "white", paddingRight: 12, fontSize: 40 }}></i>
      </a>
      <a target="_blank" href="http://www.linkedin.com/in/brandoncurran/">
        <i
          class="fa fa-linkedin"
          aria-hidden="true"
          style={{ color: "white", paddingRight: 12, fontSize: 40 }}></i>
      </a>
      <a target="_blank" href="http://www.instagram.com/playerspunch">
        <i
          class="fa fa-instagram"
          aria-hidden="true"
          style={{ color: "white", paddingRight: 12, fontSize: 40 }}></i>
      </a>
    </div>
  );
}

export default SocialsStack;
