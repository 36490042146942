import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFoundView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return <h1></h1>;
};

export default PageNotFoundView;
