import pp_profile from "../images/pp_profile.png";
import "./HeaderView.css";

const HeaderView = () => {
  return (
    <div className="header-view">
      <img src={pp_profile} alt="Player's Punch secondary logo"></img>
    </div>
  );
};

export default HeaderView;
