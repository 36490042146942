import players_punch_final from "../images/players_punch_final.png";
import "./PrimaryLogoView.css";

const PrimaryLogoView = () => {
  return (
    <div className="primary-logo-container">
      <img src={players_punch_final} alt="Player's Punch primary logo"></img>
    </div>
  );
};

export default PrimaryLogoView;
